import { mapActions } from 'vuex';

export default {
  name: 'ModalHeader',

  props: {
    icon: {
      default: null,
      type: [Array, String],
    },
    iconBackgroundColor: {
      default: '#bbb',
      type: String,
    },
    iconColor: {
      default: '#58595b',
      type: String,
    },
    size: {
      default: 'lg',
      type: String,
    },
  },

  methods: {
    ...mapActions('modals', ['hideModal']),
  },
};
